<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<OnSiteMessageCreateAndFilter
			ref="create-and-filter"
			:create-link="createLink"
			:found="list.meta.total"
			:message-type="messageType"
		/>

		<CRow>
			<CCol>
				<BaseTable
					:pagination="{
						pages: list.meta.lastPage,
						activePage: list.meta.currentPage,
					}"
					:fields="tableFields"
					:items="dataTable"
					clickable-rows
					:link-to="editLink"
					vertical-align="top"
					class="table-custom table-custom-link"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{
									$t('global.searchNotFound', {
										field: 'Campaign',
									})
								}}
							</p>
						</div>
					</template>

					<template #periodStatus="{item}">
						<CBadge class="badge-status" :color="PERIOD_STATUS_COLOR[item.value]">
							{{ item.label }}
						</CBadge>
					</template>

					<template #status="{item}">
						<CBadge class="badge-status" :color="STATUS_COLOR[item.value]">
							{{ item.label }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import OnSiteMessageCreateAndFilter from '@/components/OnSiteMessageCreateAndFilter.vue';

import {
	TYPES as ON_SITE_MESSAGE_TYPES,
	TABLE_FIELDS,
	PERIOD_STATUS_COLOR,
	STATUS_COLOR,
} from '../enums/onSiteMessages';

export default {
	name: 'OnSiteMessageGroupList',

	components: {
		OnSiteMessageCreateAndFilter,
	},

	props: {
		createLink: {
			type: String,
			default: '',
		},
		editLink: {
			type: String,
			default: '',
		},
		messageType: {
			type: String,
			default: ON_SITE_MESSAGE_TYPES.POPUP,
		},
	},

	data() {
		return {
			TABLE_FIELDS,
			PERIOD_STATUS_COLOR,
			STATUS_COLOR,

			isLoading: false,
		};
	},

	computed: {
		...mapState('onSiteMessages', {
			list: 'list',
		}),

		...mapGetters({
			getCampaignList: 'onSiteMessages/getCampaignList',
		}),

		dataTable() {
			return this.getCampaignList;
		},

		tableFields() {
			return TABLE_FIELDS;
		},
	},

	async created() {
		// TODO: improvement when have strip banner
		let campaigns;

		switch (this.messageType) {
			case ON_SITE_MESSAGE_TYPES.POPUP:
				campaigns = this.getPopupCampaigns({
					...this.$route.query,
				});
				break;
			default:
				campaigns = this.getStripCampaigns({
					...this.$route.query,
				});
		}

		this.isLoading = true;

		await Promise.all([
			campaigns,
		]);

		this.isLoading = false;
	},

	methods: {
		...mapActions({
			getPopupCampaigns: 'onSiteMessages/getPopupCampaigns',
			getStripCampaigns: 'onSiteMessages/getStripCampaigns',
		}),

		handlePageChange(page) {
			this.$refs['create-and-filter'].handlePageChange(page);
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .table-custom {
		.banner-name {
			max-width: rem(350);
		}
	}
</style>
