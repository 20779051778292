var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('BaseLoading'):_c('div',[_c('OnSiteMessageCreateAndFilter',{ref:"create-and-filter",attrs:{"create-link":_vm.createLink,"found":_vm.list.meta.total,"message-type":_vm.messageType}}),_c('CRow',[_c('CCol',[_c('BaseTable',{staticClass:"table-custom table-custom-link",attrs:{"pagination":{
					pages: _vm.list.meta.lastPage,
					activePage: _vm.list.meta.currentPage,
				},"fields":_vm.tableFields,"items":_vm.dataTable,"clickable-rows":"","link-to":_vm.editLink,"vertical-align":"top"},on:{"onPaginationClick":_vm.handlePageChange},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"empty-table-element"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('global.searchNotFound', { field: 'Campaign', }))+" ")])])]},proxy:true},{key:"periodStatus",fn:function(ref){
				var item = ref.item;
return [_c('CBadge',{staticClass:"badge-status",attrs:{"color":_vm.PERIOD_STATUS_COLOR[item.value]}},[_vm._v(" "+_vm._s(item.label)+" ")])]}},{key:"status",fn:function(ref){
				var item = ref.item;
return [_c('CBadge',{staticClass:"badge-status",attrs:{"color":_vm.STATUS_COLOR[item.value]}},[_vm._v(" "+_vm._s(item.label)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }