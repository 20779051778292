<template>
	<div class="tabs-container-large">
		<div class="tabs-wrapper">
			<CTabs>
				<CTab
					v-for="(item, index) in components"
					:key="index"
					:title="item.title"
					:active="item.routerTo === $router.currentRoute.name"
					:to="{ name: item.routerTo }"
				>
					<div class="c-main">
						<div class="container-fluid">
							<component
								:is="item.component"
								:create-link="item.routerToCreate"
								:edit-link="item.routerToEdit"
								:message-type="messageType"
							/>
						</div>
					</div>
				</CTab>
			</CTabs>
		</div>
	</div>
</template>

<script>
import OnSiteMessageGroupList from '@/components/OnSiteMessageGroupList.vue';

import { TYPES as ON_SITE_MESSAGE_TYPES, ON_SITE_MESSAGE_TABS, ON_SITE_MESSAGE_COMPONENTS } from '../enums/onSiteMessages';

export default {
	name: 'OnSiteMessageList',

	components: {
		OnSiteMessageGroupList,
	},

	props: {
		messageType: {
			type: String,
			default: ON_SITE_MESSAGE_TYPES.POPUP,
		},
	},

	computed: {
		components() {
			return ON_SITE_MESSAGE_TABS.map((tab) => ON_SITE_MESSAGE_COMPONENTS[tab]);
		},
	},
};
</script>
